import React from 'react'
import { Link } from 'react-router-dom';
const MainPage = () => {
  return (
            <div className="container-fluid">

              <div className="top-alerts">


                <div className="alert alert-success alert-dismissible fade show" role="alert">
                  <strong>Congratulations!</strong> You Sold 850 Tickets This Month.
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>




              </div>

              <div className="quick-access">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <h1 className="h3 mb-0 text-gray-800">Quick Access</h1>

                </div>
                <div className="row">

                  <div className="col-xl-3 col-md-6 mb-4">
                    <Link to="/trips/add"><button type="button" className="btn btn-primary quick-access-btn"><i className="fas fa-plus"></i> Add Trip</button></Link>

                  </div>
                  <div className="col-xl-3 col-md-6 mb-4">
                    <Link to="/bookings/add"><button type="button" className="btn btn-success quick-access-btn"><i className="fas fa-plus"></i> Add Booking</button></Link>

                  </div>
                  <div className="col-xl-3 col-md-6 mb-4">
                    <Link to="/coupons/add"><button type="button" className="btn btn-warning quick-access-btn"><i className="fas fa-plus"></i> Add Coupon</button></Link>

                  </div>

                  <div className="col-xl-3 col-md-6 mb-4">
                    <Link to="/refunds/add"><button type="button" className="btn btn-danger quick-access-btn"><i className="fas fa-plus"></i> Create Refund</button></Link>

                  </div>


                </div>
              </div>

              {/* <!-- Page Heading --> */}
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                <Link to="/" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i className="fas fa-download fa-sm text-white-50"></i> Generate Report</Link>
              </div>

              {/* <!-- Content Row --> */}
              <div className="row">

                {/* <!-- Earnings (Monthly) Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-primary shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">Today's Revenue</div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">EGP 12,750</div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-pound-sign fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Earnings (Monthly) Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-primary shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">Today's Bookings</div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">37</div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-book fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Earnings (Monthly) Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-danger shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-success text-uppercase mb-1">This Month Revenue</div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">EGP 37,900</div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-pound-sign fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Earnings (Monthly) Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-danger shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-success text-uppercase mb-1">This Month Bookings</div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">112</div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-book fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Pending Requests Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-success shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">Active Trips</div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">3</div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-plane fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                {/* <!-- Pending Requests Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">Total Trips</div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">18</div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-bus fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Pending Requests Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">Total Users</div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">18</div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-user fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Earnings (Monthly) Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-dark shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-success text-uppercase mb-1">Total Revenue</div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">EGP 124,450</div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-pound-sign fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



              </div>

              {/* <!-- Content Row --> */}

              <div className="row">

                {/* <!-- Area Chart --> */}
                <div className="col-xl-8 col-lg-7">
                  <div className="card shadow mb-4">
                    {/* <!-- Card Header - Dropdown --> */}
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">This Week Bookings</h6>

                    </div>
                    {/* <!-- Card Body --> */}
                    <div className="card-body">
                      <div className="chart-area">
                        <canvas id="myAreaChart"></canvas>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Pie Chart --> */}
                <div className="col-xl-4 col-lg-5">
                  <div className="card shadow mb-4">
                    {/* <!-- Card Header - Dropdown --> */}
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">Refund Percentage</h6>

                    </div>
                    {/* <!-- Card Body --> */}
                    <div className="card-body">
                      <div className="chart-pie pt-4 pb-2">
                        <canvas id="myPieChart"></canvas>
                      </div>
                      <div className="mt-4 text-center small">
                        <span className="mr-2">
                          <i className="fas fa-circle text-success"></i> Success
                    </span>
                        <span className="mr-2">
                          <i className="fas fa-circle text-danger"></i> Refund
                    </span>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  );
}


export default MainPage