import React, { createRef, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import UserContext from "../../AuthContext/auth";
import FullRequest from "../../functions/FullRequest";
import { loadData } from "../../functions/loadData";
import PostRequest from "../../functions/PostRequest";
const TripForm = () => {
	const [stations, setStations] = useState({ data: [], loading: true });
	const [display, setDisplay] = useState("none");
	const ErrorMessage = createRef();
	const history = useHistory();
	useEffect(() => {
		const fetching = () => {
			loadData("/api/stations", setStations, null);
		};
		fetching();
	}, []);

	const name = createRef();
	const totalSeats = createRef();
	const availableSeats = createRef();
	const TravelBy = createRef();
	const TravelByInfo = createRef();
	const Hotel = createRef();
	const HotelArabic = createRef();
	const Days = createRef();
	const Nights = createRef();
	const included = createRef();
	const includedArabic = createRef();
	const program = createRef();
	const programArabic = createRef();
	const price = createRef();
	const Departure = createRef();
	const Arrival = createRef();
	const Departure_station = createRef();
	const Arrival_station = createRef();
	const Departure_Location_info = createRef();
	const Departure_location_info_arabic = createRef();
	const linkParam = createRef();
	const backgroundImage = createRef();
  const images = createRef();
  const excluded = createRef();
  const excludedArabic = createRef();
  const activites = createRef();
  const activitesArabic = createRef();

	//TODO: get companyID from Token
	const authContext = useContext(UserContext);
	const GetID = (RefVal) => {
		const index = RefVal.current.selectedIndex;
		const id = RefVal.current[index].getAttribute("data_id");
		const [obj] = stations.data.filter((S) => {
			return S._id === id;
		});

		return obj._id;
	};

	const HandelAutoFill = () => {
		name.current.value = "Any Name";
		totalSeats.current.value = 100;
		availableSeats.current.value = 100;
		TravelByInfo.current.value = "Some information about the trip";
		Hotel.current.value = "trivago";
		HotelArabic.current.value = "ترافيجو";
		Days.current.value = 10;
		Nights.current.value = 11;
		included.current.value = "this is useless";
    includedArabic.current.value = "This is even more useless but in arabic";
    excluded.current.value = "no pool";
    excludedArabic.current.value = "لا يوجد حمام سباحة";
    activites.current.value = "running";
    activitesArabic.current.value = "جري";
		program.current.value = "you might enjoy this trip no guarantees and NO REFUND";
		programArabic.current.value = "قد تستمتع بهذة الرحلة لايوجد ضمان و مفيش فلوس هترجع :)";
		price.current.value = 1000;
		Departure.current.value = Date.now();
		Arrival.current.value = Date.now() + 20;
		Departure_station.current.value = "Cairo";
		Arrival_station.current.value = "Alex";
		Departure_Location_info.current.value = "Very cool location";
		Departure_location_info_arabic.current.value = "مكان رائع حقا!";
	};
	const handelSubmit = async (event) => {
		event.preventDefault();

		//handeling if stations is null

		if (!stations) {
			ErrorMessage.current.innerHtml = "internal server Error! please refresh the page or try again later";
			setDisplay("block");
		}
		const body = {
			name: name.current.value,
			travel_by: TravelBy.current.value,
			travel_by_info: TravelByInfo.current.value,
			included: included.current.value,
      included_ar: includedArabic.current.value,
      excluded: excluded.current.value,
      excluded_ar: excludedArabic.current.value,
      activites: activites.current.value,
      activites_ar: activitesArabic.current.value,
			program_info: program.current.value,
			program_info_ar: programArabic.current.value,
			departure_station_info: Departure_Location_info.current.value,
			departure_station_info_ar: Departure_location_info_arabic.current.value,
			nights: Nights.current.value,
			days: Days.current.value,
			hotel: Hotel.current.value,
			hotel_ar: HotelArabic.current.value,
			price: price.current.value,
			departure: Departure.current.value,
			return: Arrival.current.value,
			departure_station: GetID(Departure_station),
			arrival_station: GetID(Arrival_station),
			seats_total: totalSeats.current.value,
			seats_available: availableSeats.current.value,
			link_params: `randomValue ${Math.random(1, 10000)}`,
			active: true,
			reserved: 0,
			down_payment: false,
			down_payment_value: 0,
			//TODO: add link param
		};
		try {
			const response = await FullRequest(authContext, "/api/trips/create", body);

			const final = await response.json();
			console.log(backgroundImage.current.files);
			const formData = new FormData();
			formData.append("background", backgroundImage.current.files[0], backgroundImage.current.files[0].name);
			const backGroundRequest = await fetch(`/api/trips/${final.output}/background`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${authContext.token}`,
				},
				body: formData,
      });
  
			console.log(await backGroundRequest.json());
      formData.delete("background");
      [...images.current.files].forEach(file=>{
        formData.append("images", file);
      });
      
    
      const uploadImagesRequest = await fetch(`/api/trips/${final.output}/images`, {
        method: "POST",
        headers: {
  
          Authorization: `Bearer ${authContext.token}`,
        },
        body: formData,
      });
      console.log(uploadImagesRequest);
			console.log(await uploadImagesRequest.json());
			history.push(`/trips/${final.output}`);
		} catch (e) {
			console.log(e);
			ErrorMessage.current.innerHtml = "Failed to add new Trip!!";
			setDisplay("block");
		}
	};
	return (
		<div className="container-fluid">
			<h1 className="h3 mb-2 text-gray-800">Add Trip</h1>
			<p className="mb-4">You can add new trip here, Your trip will be pending until Viajero team review it before approval, Once it approved it will be shown to all users through Viajero website and Apps.</p>

			<div className="card shadow mb-4">
				<div className="card-header py-3 ">
					<div className="row row-table-with-btn">
						<div className="col-xl-6 col-md-6 mb-6 sub-head-with-btn">
							<h6 className="m-0 font-weight-bold text-primary">Trip Details</h6>
						</div>
						<div className="col-xl-6 col-md-6 mb-6 sub-head-with-btn">
							<button onClick={HandelAutoFill}>Auto Fill Form</button>
						</div>
					</div>
				</div>
				<div className="card-body">
					<form onSubmit={handelSubmit}>
						<div className="table-responsive">
							<table className="table table-bordered" width="100%" cellSpacing="0">
								<thead>
									<tr>
										<th>Data</th>
										<th>Input</th>
										<th>Example</th>
									</tr>
								</thead>

								<tbody>
									<tr>
										<td>Name</td>
										<td>
											<input required type="text" className="form-control" aria-label="" ref={name} />
										</td>
										<td>Dahab Summer Trip</td>
									</tr>

									<tr>
										<td>Total Seats</td>
										<td>
											<input
												required
												type="number"
												className="form-control"
												aria-label=""
												onChange={(e) => {
													availableSeats.current.value = e.target.value;
												}}
												ref={totalSeats}
											/>
										</td>
										<td>50</td>
									</tr>

									<tr>
										<td>Available Seats</td>
										<td>
											<input required type="number" className="form-control" aria-label="" ref={availableSeats} />
										</td>
										<td>40</td>
									</tr>

									<tr>
										<td>Travel by</td>
										<td>
											<select required className="form-control" defaultValue={"Bus"} id="sel1" ref={TravelBy}>
												<option disabled value="">
													{" "}
													Please Choose Transportation Type :{" "}
												</option>
												<option>Bus</option>
												<option>Train</option>
												<option>Plane</option>
											</select>
										</td>
										<td>Bus</td>
									</tr>

									<tr>
										<td>Travel By Info</td>
										<td>
											<input required type="text" className="form-control" aria-label="" ref={TravelByInfo} />
										</td>
										<td>Mercedes MCV500</td>
									</tr>

									<tr>
										<td>Hotel</td>
										<td>
											<input required type="text" className="form-control" aria-label="" ref={Hotel} />
										</td>
										<td>Tirana Hotel</td>
									</tr>

									<tr>
										<td>Hotel - ARABIC</td>
										<td>
											<input required type="text" className="form-control" aria-label="" ref={HotelArabic} />
										</td>
										<td>فندق تيرانا</td>
									</tr>

									<tr>
										<td>Hotel Stars Rating</td>
										<td>
											<select required className="form-control" id="sel1">
												<option disabled value="">
													Please Choose Hotel Star Rating :{" "}
												</option>
												<option>1</option>
												<option>2</option>
												<option>3</option>
												<option>4</option>
												<option>5</option>
											</select>
										</td>
										<td>
											4 Stars <i className="fas fa-star"> </i>
											<i className="fas fa-star"> </i>
											<i className="fas fa-star"> </i>
											<i className="fas fa-star"> </i>
										</td>
									</tr>

									<tr>
										<td>Days</td>
										<td>
											<input min="1" required type="number" className="form-control" aria-label="" ref={Days} />
										</td>
										<td>3 Days</td>
									</tr>

									<tr>
										<td>Nights</td>
										<td>
											<input min="0" required type="number" className="form-control" aria-label="" ref={Nights} />
										</td>
										<td>4 Nights</td>
									</tr>

									<tr>
										<td>Included</td>
										<td>
											<textarea required type="text" className="form-control" aria-label="" ref={included}></textarea>
										</td>
										<td>A short Summer Trip to relax on the red sea, Half board hotel.... etc</td>
									</tr>

									<tr>
										<td>Included - ARABIC </td>
										<td>
											<textarea required type="text" className="form-control" aria-label="" ref={includedArabic}></textarea>
										</td>
										<td>رحلة صيفية للاستجمام أمام البحر الأحمر, اقامة بالفطار ...</td>
									</tr>

                  <tr>
										<td>Excluded</td>
										<td>
											<textarea required type="text" className="form-control" aria-label="" ref={excluded}></textarea>
										</td>
										<td>A short Summer Trip to relax on the red sea, Half board hotel.... etc</td>
									</tr>

									<tr>
										<td>Excluded - ARABIC </td>
										<td>
											<textarea required type="text" className="form-control" aria-label="" ref={excludedArabic}></textarea>
										</td>
										<td>رحلة صيفية للاستجمام أمام البحر الأحمر, اقامة بالفطار ...</td>
									</tr>

                  <tr>
										<td>Activites</td>
										<td>
											<textarea required type="text" className="form-control" aria-label="" ref={activites}></textarea>
										</td>
										<td>A short Summer Trip to relax on the red sea, Half board hotel.... etc</td>
									</tr>

									<tr>
										<td>Activites - ARABIC </td>
										<td>
											<textarea required type="text" className="form-control" aria-label="" ref={activitesArabic}></textarea>
										</td>
										<td>رحلة صيفية للاستجمام أمام البحر الأحمر, اقامة بالفطار ...</td>
									</tr>

									<tr>
										<td>Program </td>
										<td>
											<textarea required type="text" className="form-control" aria-label="" ref={program}></textarea>
										</td>
										<td>Day 1 : Arrive at 10 AM, Have breakfast, Day 2 : Go to Blue Hole....</td>
									</tr>

									<tr>
										<td>Program - ARABIC </td>
										<td>
											<textarea required type="text" className="form-control" aria-label="" ref={programArabic}></textarea>
										</td>
										<td>اليوم الأول : الوصول 10 صباحا, اليوم الثانى : الذهاب الى البلو هول ...</td>
									</tr>

									<tr>
										<td>Price</td>
										<td>
											<input required min="0" type="number" className="form-control" aria-label="" ref={price} />
										</td>
										<td>1250 EGP</td>
									</tr>

									<tr>
										<td>Departure</td>
										<td>
											<input type="date" id="departure" ref={Departure} />
										</td>
										<td>11/25/2020 02:30 AM</td>
									</tr>

									<tr>
										<td>Return</td>
										<td>
											<input type="date" id="departure" ref={Arrival} />
										</td>
										<td>11/26/2020 10:00 PM</td>
									</tr>

									<tr>
										<td>Departure Station</td>
										<td>
											<select required className="form-control" id="sel1" ref={Departure_station}>
												<option disabled value="">
													{" "}
													Please Choose Station :{" "}
												</option>
												{!stations.loading ? (
													stations.data.map((S) => {
														return (
															<option key={S._id} data_id={S._id}>
																{S.name}
															</option>
														);
													})
												) : (
													<React.Fragment>
														<option>Cairo</option>
														<option>Alexandria</option>
														<option>Dahab</option>
													</React.Fragment>
												)}
											</select>
										</td>
										<td>Cairo</td>
									</tr>

									<tr>
										<td>Arrival Station</td>
										<td>
											<select
												required
												className="form-control"
												id="sel1"
												defaultValue="Alexandria"
												ref={Arrival_station}
												onChange={() => {
													GetID(Arrival_station);
												}}
											>
												<option disabled value="">
													{" "}
													Please Choose Station :{" "}
												</option>
												{!stations.loading ? (
													stations.data.map((S) => {
														return (
															<option key={S._id} data_id={S._id}>
																{S.name}
															</option>
														);
													})
												) : (
													<React.Fragment>
														<option>Cairo</option>
														<option>Alexandria</option>
														<option>Dahab</option>
													</React.Fragment>
												)}
											</select>
										</td>
										<td>Dahab</td>
									</tr>

									<tr>
										<td>Departure Location Info</td>
										<td>
											<textarea required type="text" className="form-control" aria-label="" ref={Departure_Location_info}></textarea>
										</td>
										<td>Abdelmounem Reyad Square - Tahrir</td>
									</tr>

									<tr>
										<td>Departure Location Info - ARABIC </td>
										<td>
											<textarea required type="text" className="form-control" aria-label="" ref={Departure_location_info_arabic}></textarea>
										</td>
										<td>ميدان عبد المنعم رياض - التحرير</td>
									</tr>

									<tr>
										<td>Main Image ( Background Image ) </td>
										<td>
											<div className="custom-file">
												<input type="file" className="custom-file-input" id="customFile" name="background" accept=".png,.jpeg,.jpg" ref={backgroundImage} />
												<label className="custom-file-label" htmlFor="customFile">
													Choose file
												</label>
											</div>
										</td>
										<td>The Main Image Will Be Shown As the Trip Image on the Website and Apps.</td>
									</tr>

									<tr>
										<td>Trip Images ( Maximum 20 Image! ) </td>
										<td>
											<div className="custom-file">
												<input multiple type="file" className="custom-file-input" name="images" id="customFile" accept=".png,.jpeg,.jpg" ref={images} />
												<label className="custom-file-label" htmlFor="customFile">
													Choose file
												</label>
											</div>
										</td>
										<td>The Images Will Shown as Slider In Trip Details Page.</td>
									</tr>
								</tbody>
							</table>
							<div className="add-btn-div float-xl-right">
								<button type="submit" className="btn btn-primary add-btn">
									<i className="fas fa-paper-plane"></i> Create Trip
								</button>

							</div>
							<div className="alert alert-danger alert-dismissible fade show" role="alert" style={{ display: display }}>
								<strong ref={ErrorMessage}></strong>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default TripForm;
