import React, { createRef, useContext, useEffect, useState } from "react";
import { loadData } from "../../functions/loadData";
import jwt_decode from "jwt-decode";
import "../addBooking/addBooking.css";
import UserContext from "../../AuthContext/auth";
import {HandelRequestFailure} from "../../functions/helpers"; 
import PostRequest from "../../functions/PostRequest";
const BookingForm = () => {
	const authContext = useContext(UserContext);
	const [display, setDisplay] = useState("none");
	const ErrorMessage = createRef();
	// const history = useHistory();

	const token = authContext.token;

	const [trips, setTrips] = useState({data:[],loading:true});
	const handelOnChangeSelect = async () => {
		loadData("/api/trips/company",setTrips,token);

	};

	useEffect(() => {
		loadData("/api/trips/company",setTrips,token)		
	}, []);
	
	const tripName = createRef();
	const name = createRef();
	const Phone = createRef();
	const NumberOftickets = createRef();
	const totalPrice = createRef();
	const tripType = createRef();

	const GetTrip = () => {
		const index = tripName.current.selectedIndex;
		const id = tripName.current[index].getAttribute("data_id");
		console.log(id);
		const [singleTrip] = trips.data.filter((T) => {
			return T._id === id;
		});
		return singleTrip;
	};
	const handleOnChange = async () => {
		
		const singleTrip = GetTrip();

		totalPrice.current.innerText = +singleTrip.price * +NumberOftickets.current.value;
	};

	const handleOnSubmit = async (event) => {
		event.preventDefault();
		const DecodedToken = jwt_decode(token);

		try {
			const cartBody = JSON.stringify({
				trip: GetTrip()._id,
				bus: null,
				NumberOfSeats: NumberOftickets.current.value,
				user_id: DecodedToken._id,
			});

			const createCartRequest = await PostRequest("/api/cart/create",cartBody,token);
			
			await HandelRequestFailure(createCartRequest);
			const cartID = await createCartRequest.json();
			console.log(cartID.output);
			const passengerBody = {
				name: name.current.value,
				phone: Phone.current.value,
			};
			const createPassgengerRequest = await PostRequest("/api/passengers/create",passengerBody,token);
		
			await HandelRequestFailure(createPassgengerRequest);

			const passenger = await createPassgengerRequest.json();
			console.log(passenger.output);

			const bookingBody = {
				user: DecodedToken._id,
				creator: DecodedToken._id,
				cart: cartID.output,
				prebooked: false,
				passenger: passengerBody,
			};
			const createBooking = await PostRequest("/api/bookings/create",bookingBody,token);
			await HandelRequestFailure(createBooking);
			const booking = await createBooking.json();
			const companyBookingBody = {
				company: DecodedToken.company_id,
				booking: booking.output,
				passenger: passenger.output, 
			};
			const createCompanyBooking = await PostRequest("/api/companybookings/create",companyBookingBody,token);
			await HandelRequestFailure(createCompanyBooking);
			
		} catch (e) {
			ErrorMessage.current.innerText = e;
			setDisplay("block");
			return null;
		}
	};
	return (
					
						<div className="container-fluid">
							<h1 className="h3 mb-2 text-gray-800">Add Booking</h1>
							
							<div className="card shadow mb-4">
								<div className="card-header py-3 ">
									<div className="row row-table-with-btn">
										<div className="col-xl-6 col-md-6 mb-6 sub-head-with-btn">
											<h6 className="m-0 font-weight-bold text-primary">Booking Data</h6>
										</div>
										<div className="col-xl-6 col-md-6 mb-6 sub-head-with-btn"></div>
									</div>
								</div>
								<div className="card-body">
									<form onSubmit={handleOnSubmit}>
										<div className="table-responsive">
											<table className="table table-bordered" width="100%" cellSpacing="0">
													<thead>
													<tr>
														<th>Data</th>
														<th>Input</th>
													</tr>
												</thead>

												<tbody>
													<tr>
														<td>
															<select ref={tripType} onChange={handelOnChangeSelect}>
																<option>trip</option>
																<option>bus</option>
															</select>
														</td>
														<td>
															<select
																required
																className="form-control"
																id="sel1"
																ref={tripName}
															>
																{trips ? (
																	trips.data.map((T) => {
																		return (
																			<option key={T._id} data_id={T._id} >
																				{T.name +" "+new Date(T.departure).toLocaleString()}
																			</option>
																			
																		);
																	})
																) : (
																	<React.Fragment>
																		<div className="loader"></div>
																	</React.Fragment>
																)}
																
															</select>
														</td>
													</tr>
													<tr>
														<td>Full name</td>
														<td>
															<input
																required
																type="text"
																className="form-control"
																aria-label=""
																ref={name}
															/>
														</td>
													</tr>
													<tr>
														<td>phone number</td>
														<td>
															<input
																required
																type="number"
																className="form-control"
																aria-label=""
																ref={Phone}
																defaultValue="+2"
	
															/>
														</td>
													</tr>
													<tr>
														<td>Number of seats</td>
														<td>
															<input
																required
																type="number"
																className="form-control"
																aria-label=""
																onChange={handleOnChange}
																ref={NumberOftickets}
																
															/>
														</td>
													</tr>
												</tbody>
											</table>
											<div className="add-btn-div float-xl-right">
												<button type="submit" className="btn btn-primary add-btn">
													<i className="fas fa-paper-plane"></i> Confirm booking
												</button>
											</div>
											<div className="float-xl-left">
												TOTAL PRICE: <p ref={totalPrice} style={{ color: "red" }}>0</p>
											</div>

											<div
												className="alert alert-danger alert-dismissible fade show"
												role="alert"
												style={{ display: display }}
											>
												<strong ref={ErrorMessage}></strong>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
	);
};

export default BookingForm;
