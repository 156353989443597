export const loadData = async (URL,setData ,token) => {
    
    const response = await fetch(URL,{
        method:"GET",
        headers:{
            "Content-Type":"application/json",
            "Authorization":`Bearer ${token}`
        }
    });
    
    if (response.ok) {
     
      const Data = await response.json();
      if (URL === "/api/stations"){
        return setData({data:Data,loading:false});
      }
      return setData({data:Data.output,loading:false});
      
    } 
};
