import React, { useContext } from 'react'
import BookingForm from '../components/addBooking/addBooking'
import { useHistory } from "react-router-dom";
import UserContext from '../AuthContext/auth'
import Wrapper from '../components/Wrapper/wrapper';
const AddBooking =()=>{
    const {token} = useContext(UserContext);
    const history = useHistory();
    console.log(token);
    if (token){
        return (
            <Wrapper childern={<BookingForm/> }/>     
        );
    }
    history.push('/login');
    return(<div></div>);
}

export default AddBooking