import React, { useState, useEffect, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import UserContext from '../../AuthContext/auth';
import { loadData } from '../../functions/loadData';
import Spinner from '../spinner/spinner';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
const AllBookings = () => {
    const [bookings, setBookings] = useState({ data: [], loading: true });
    const authData = useContext(UserContext);
    const token = authData.token;
    const history = useHistory();
    useEffect(() => {
        const fetching = () => {
            loadData("/api/bookings/company",setBookings, token);
        };
        fetching();

    }, []);
    function handelRefresh(e){
        e.preventDefault();
        loadData("/api/bookings/company",setBookings, token);
    }
    function handelDescSort(e){
        if (e.target.id === "descDate"){
            setBookings((prev)=>{
                return {data :prev.data.sort((a,b)=>{
                    let c = new Date(a.created_at);
                    let d = new Date (b.created_at);
                    return d>c;
                }) , loading:false}
            });
           
            return null;
        }

        const key=  e.target.id;
        setBookings((prev)=>{
            return {data :prev.data.sort((a,b)=>{
                return b[key]>a[key];
            }) , loading:false}
        });
        return null;
    }
 
    function handelAsceSort(e){
        if (e.target.id === "asceDate"){
            setBookings((prev)=>{
                return {data :prev.data.sort((a,b)=>{
                    let c = new Date(a.created_at);
                    let d = new Date (b.created_at);
                    return d<c;
                }) , loading:false}
            });
            return null;
        }

        const key=  e.target.id;
        setBookings((prev)=>{
            return {data :prev.data.sort((a,b)=>{
                return b[key]<a[key];
            }) , loading:false}
        });
        return null;
    }
    return (
                        <div className="container-fluid">
                           

                            <h1 className="h3 mb-2 text-gray-800">Bookings</h1>


                            <div className="card s  hadow mb-4">
                                <div className="card-header py-3 ">
                                    <div className="row row-table-with-btn">
                                        <div className="col-xl-6 col-md-6 mb-6 sub-head-with-btn">
                                            <h6 className="m-0 font-weight-bold text-primary">All Your Bookings</h6>
                                            <button onClick={handelRefresh}>Refresh</button>
                                        </div>
                                        <div className="col-xl-6 col-md-6 mb-6 sub-head-with-btn">
                                            <button type="button" className="btn btn-primary add-btn" onClick={()=>{
                                                history.push('/bookings/add')
                                            }}><i className="fas fa-plus"></i> Add Booking</button>
                                        </div>
                                    </div>
                                </div>
                                {(bookings.loading)? <Spinner/>:(<div className="card-body">
                                    
                                    <div className="table-responsive">
                                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr>
                                                    <th title="Sort by Date" 
                                                     >booking date 
                                                        <button id="asceDate" onClick={handelAsceSort}>asce</button>
                                                        <button id="descDate" onClick={handelDescSort}>desc</button></th>
                                                    <th>name</th>
                                                    <th>phone</th>
                                                    <th>trip</th>
                                                    <th >paid
                                                        <button id="paid_amount" onClick={handelAsceSort}>asce</button>
                                                        <button id="paid_amount" onClick={handelDescSort}>desc</button>
                                                     </th>
                                                    <th >number of seats
                                                        <button id="NumberOfSeats" onClick={handelAsceSort}>asce</button>
                                                        <button id="NumberOfSeats" onClick={handelDescSort}>desc</button>
                                                    </th>
                                                    <th>viajero booking</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                 bookings.data.map(booking => {
                                                    return <tr key={booking.id}>
                                                        <td>{new Date (booking.created_at).toLocaleDateString('ar-EG',{
                                                            weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
                                                        })}</td>
                                                        <td>{booking.name}</td>
                                                        <td>{booking.phone}</td>
                                                        <td onClick={()=>{
                                                            history.push(`/trips/${booking.trip}`)
                                                        }}>{booking.trip}</td>
                                                        <td>{booking.paid_amount} LE</td>
                                                        <td>{booking.NumberOfSeats}</td>
                                                        <td>{booking.viajero.toString()}</td>
                                                        
                                                        <td>
                                                            <button type="button" title = "click to view more trip details" className="btn btn-success" onClick={()=>{
                                                                history.push(`/trips/${booking.trip}`)
                                                            }}>View trip</button>
                                                            <button type="button" className="btn btn-danger">cancel booking</button>
                                                        </td>
                                                        </tr>
                                                })}

                                            </tbody>
                                        </table>
                                        <ReactHTMLTableToExcel
                                              id="test-table-xls-button"
                                              className="download-table-xls-button"
                                              table="dataTable"
                                              filename="Bookings"
                                              sheet="Bookings"
                                              buttonText="Generate Excel Sheet"
                                        />
                                    </div>
                                </div>)}
                            </div>
                        </div>

    );
};


export default AllBookings;
