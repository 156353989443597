import React from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";
const SideNavBar = () => {
	return (
		<ul
			className="navbar-nav sidebar sidebar-dark SideNavBar accordion"
			style={{ backgroundColor: "#262F40" }}
			id="accordionSidebar"
		>
			<NavLink className="sidebar-brand d-flex align-items-center justify-content-center" to="/">
				<img id="logo-icon" src="../../Viajero-Logo.jpg" alt="company logo" style={{width:"200px"}}/>
			</NavLink>

			<hr className="sidebar-divider my-0" />

			<li className="nav-item">
				<NavLink activeClassName="selected" exact className="nav-link " to="/">
					<span>Dashboard</span>
				</NavLink>
			</li>

			<hr className="sidebar-divider" />

			<div className="sidebar-heading">Manage</div>

			<li className="nav-item">
				<NavLink activeClassName="selected" className="nav-link" to="/bookings">
					<span>Bookings</span>
				</NavLink>
			</li>

			<li className="nav-item">
				<NavLink activeClassName="selected" className="nav-link" to="/down-payments">
					<span>Down Payments</span>
				</NavLink>
			</li>

			<li className="nav-item">
				<NavLink activeClassName="selected" className="nav-link" to="/trips">
					<i className="fas fa-fw fa-plane"></i>
					<span>Trips</span>
				</NavLink>
			</li>
			<li className="nav-item">
				<NavLink activeClassName="selected" className="nav-link" to="/tickets">
					<span>Tickets</span>
				</NavLink>
			</li>
			<li className="nav-item">
				<NavLink activeClassName="selected" className="nav-link" to="/passengers">
					<i className="fas fa-fw fa-user"></i>
					<span>Passengers</span>
				</NavLink>
			</li>

			<hr className="sidebar-divider" />

			<div className="sidebar-heading">Addons</div>

			<li className="nav-item">
				<NavLink activeClassName="selected" className="nav-link" to="/coupons">
					<i className="fas fa-fw fa-tags"></i>
					<span>Coupons</span>
				</NavLink>
			</li>

			<hr className="sidebar-divider" />

			<div className="sidebar-heading">Support</div>

			<li className="nav-item">
				<NavLink activeClassName="selected" className="nav-link" to="/refunds">
					<i className="fas fa-fw fa-times"></i>
					<span>Refunds</span>
				</NavLink>
			</li>

			<hr className="sidebar-divider" />

			<div className="sidebar-heading">Settings</div>

			<li className="nav-item">
				<NavLink activeClassName="selected" className="nav-link" to="/financial">
					<span>Financial</span>
				</NavLink>
			</li>

			<li className="nav-item">
				<NavLink activeClassName="selected" className="nav-link" to="/company">
					<span>Profile</span>
				</NavLink>
			</li>

			<hr className="sidebar-divider d-none d-md-block" />

			<div className="text-center d-none d-md-inline">
				<button className="rounded-circle border-0" id="sidebarToggle"></button>
			</div>
		</ul>
	);
};

export default SideNavBar;
