import React from "react";
import SideNavBar from "../NavigationBar/NavBar";
import TopBar from "../TopBar/topBar";
import "./wrapper.css";
const Wrapper = ({childern}) => {
	return (
		<div id="page-top">
			<div id="wrapper">
				<SideNavBar />
				<div id="content-wrapper" className="d-flex flex-column">
					<div id="content">
						<TopBar/>
						<div className="inner-wrapper">
							{childern}
						</div>
						
					</div>
				</div>
			</div>
		</div>
	);
};
export default Wrapper;
