import React from 'react'

import TripForm from '../components/addTrip/addTrip'
import Wrapper from '../components/Wrapper/wrapper';
const AddTrip =()=>{
    return (
        <Wrapper childern={<TripForm/>}/>
    );
}


export default AddTrip