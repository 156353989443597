import React, { useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import UserContext from "../../AuthContext/auth";

const Logout = () => {
	const authContext = useContext(UserContext);
	const cancelButton = useRef();
	const history = useHistory();
	async function handleLogout(){
		console.log("logout is called");
		const logoutRequest = await fetch("/api/company/logout", {
			method: "POST",
			headers: {
				Authorization: `Bearer ${authContext.token}`,
			},
		});
		if (!logoutRequest.ok) {
			return null;
		}
		cancelButton.current.click();
		authContext.logout();
		history.push("/login");
		
	};
	return (
		<div
			className="modal fade"
			id="logoutModal"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="exampleModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="exampleModalLabel">
							Ready to Leave?
						</h5>
						<button className="close" type="button" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div className="modal-body">
						Select "Logout" below if you are ready to end your current session.
					</div>
					<div className="modal-footer">
						<button className="btn btn-secondary" type="button" data-dismiss="modal" ref={cancelButton}>
							Cancel
						</button>
						<button className="btn btn-primary" data-dismiss="modal" onClick={handleLogout}>
							Logout
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Logout;
