import React from 'react'

import LoginForm from '../components/login/login'

const Login =()=>{
    
    return (
        <LoginForm/>
    );
}


export default Login