import React, { useRef, useContext, useState } from "react";

import { useHistory } from "react-router-dom";
import UserContext from "../../AuthContext/auth";
import {addMinutes} from "../../functions/helpers";

const MangeForm = () => {
	const emailRef = useRef();
	const passwordRef = useRef();
	const history = useHistory();
	const { login } = useContext(UserContext);
	const [display, setDisplay] = useState("none");

	const SendLogin = async (event) => {
		event.preventDefault();

		const Body = {
			email: emailRef.current.value,
			password: passwordRef.current.value,
		};
		try {
			const url = `/api/company/login`;
			const response = await fetch(url, {
				method: "POST",
				body: JSON.stringify(Body),
				headers: {
					"content-type": "application/json",
				},
			});
			if (!response.ok) {
				setDisplay("block");
				return;
			}

			const final = await response.json();

			login(final.output, addMinutes(Date.now(), 15));

			history.push("/");
		} catch (error) {
			setDisplay("block");
		}
	};

	return (
		<form className="user" onSubmit={SendLogin}>
			<div className="form-group">
				<input type="email" id="email" className="form-control form-control-user" aria-describedby="emailHelp" placeholder="Enter Email Address..." required ref={emailRef} />
			</div>
			<div className="form-group">
				<input type="password" id="password" className="form-control form-control-user" placeholder="Password" required ref={passwordRef} />
			</div>
			<button type="submit" className="btn btn-primary btn-user btn-block">
				Login
			</button>
			<div className="alert alert-danger alert-dismissible fade show" role="alert" style={{ display: display }}>
				<strong>Email or password were wrong!</strong>
			</div>
		</form>
	);
};

export default MangeForm;
