import React from 'react'
import CouponForm from '../components/addCoupon/addCoupon';
import Wrapper from '../components/Wrapper/wrapper';

const AddCoupon =()=>{
    return (
    <Wrapper childern={<CouponForm/>}/>
    );
}

export default AddCoupon;