export async function HandelRequestFailure(request) {
	if (!request.ok) {
		const data = await request.json();
		if (!data.output) {
			throw new Error("INTERNAL SERVER ERROR: Try again later or contanct Viajero");
		}
		throw new Error(data.output);
    }
    return null;
}

export function addMinutes(date, minutes) {
	return new Date(new Date(date).getTime() + minutes * 60000);
}