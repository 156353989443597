import {addMinutes} from "./helpers";
async function silentRefresh(login){
    const RefreshRequest = await fetch('/api/company/slient',{
        method:"GET",
        credentials:"same-origin"
    });
    if (!RefreshRequest.ok){
        return false;
    }
    const Data = await RefreshRequest.json();
    login(Data.output,addMinutes(Date.now(), 15));
    return true;
} 


export default silentRefresh;