import React from 'react';


const UserContext = React.createContext({
    token: null,
    expiration:null,
    login: ()=>{

    },
    logout: ()=>{
        
    }
});
export default UserContext;