import React from "react";

import "../../css/vendor/fontawesome-free/css/all.min.css";
import "../../css/sb-admin-2.css";
import "../login/login.css"
import MangeForm from "../manageForm/manageForm"


const LoginForm = () => {

  return (
    <div className="bg-gradient-primary">
      <div className="container">
        <div className="row justify-content-center">
          
          <div className="col-xl-10 col-lg-12 col-md-9">
       
            <div className="card o-hidden border-0 shadow-lg my-5">
    
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Companies Dashboard</h1>
                      </div>
                     
                      <MangeForm  />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
