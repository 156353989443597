import React, { useState, useEffect, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import UserContext from '../../AuthContext/auth';
import { loadData } from '../../functions/loadData';
import Spinner from '../spinner/spinner';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const AllTrips = () => {
    const [trips, setTrips] = useState({ data: [], loading: true });
    const authToken = useContext(UserContext);
    const token = authToken.token;
    const history = useHistory();
    useEffect(() => {
        const fetching = () => {
            loadData("/api/trips/company",setTrips,token);
        };
        fetching();

    },[]);
    function handelRefresh(e){
        e.preventDefault();
        loadData("/api/trips/company",setTrips,token);
    }
    return (
                        <div className="container-fluid">
                            <h1 className="h3 mb-2 text-gray-800">trips</h1>
                            <div className="card s  hadow mb-4">
                                <div className="card-header py-3 ">
                                    <div className="row row-table-with-btn">
                                        <div className="col-xl-6 col-md-6 mb-6 sub-head-with-btn">
                                            <h6 className="m-0 font-weight-bold text-primary">All Your Bookings</h6>
                                            <button onClick={handelRefresh}>Refresh</button>
                                        </div>
                                        <div className="col-xl-6 col-md-6 mb-6 sub-head-with-btn">
                                            <button type="button" className="btn btn-primary add-btn" onClick={()=>{
                                                history.push('/trips/add')
                                            }}><i className="fas fa-plus"></i> Add Trip</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {(trips.loading)?<Spinner/> :(<div className="table-responsive">
                                            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Trip</th>
                                                    <th>Id</th>
                                                    <th>price</th>
                                                    <th>Total seats</th>
                                                    <th>avaliable seats</th>
                                                    <th>down Payment</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {trips.data.map(T => {
                                                    return <tr key={T._id}>
                                                        <td>{new Date (T.departure).toLocaleDateString('ar-EG',{
                                                            weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
                                                        })}</td>
                                                        <td>{T.name}</td>
                                                        <td>{T.id}</td>
                                                        <td>{T.price}</td>
                                                        <td>{T.seats_total}</td>
                                                        <td>{T.seats_available}</td>
                                                        <td>{T.down_payment_value}</td>
                                                        <td>
                                                            <button type="button" className="btn btn-success" onClick={()=>{
                                                                history.push(`/trips/${T.link_params}`)
                                                            }}>View trip</button>
                                                            <button type="button" className="btn btn-danger">remove trip</button>
                                                        </td>
                                                        </tr>
                                                })}
                                            </tbody>
                                        </table>
                                        <ReactHTMLTableToExcel
                                              id="test-table-xls-button"
                                              className="download-table-xls-button"
                                              table="dataTable"
                                              filename="Trips"
                                              sheet="Trips"
                                              buttonText="Generate Excel Sheet"
                                        />
                                    </div>)}
                                </div>
                            </div>
                        </div>



    );
};


export default AllTrips;
