import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch, useHistory } from "react-router-dom";

import Login from "./pages/login";
import Main from "./pages/main";
import AddTrip from "./pages/AddTrip";
import UserContext from "./AuthContext/auth";
import AddBooking from "./pages/AddBooking";
import AddCoupon from "./pages/AddCoupon";
import trips from "./pages/trips";
import bookings from "./pages/bookings";
import Footer from "./components/footer/footer";
import Logout from "./components/logout/logout";
import ScrollToTop from "./components/ScrollTop/scroll";
import NotFoundPage from "./pages/NotFound";
import Coupons from "./pages/coupons";
import DownPayments from "./pages/downPayments";


//TODO: 1- Add loadable or React.lazy() to componenets

function App() {
	const [authToken, setToken] = useState({
		token: null,
		expiration:null
	});
	const history = useHistory();
	const authContext = useContext(UserContext);
	const login = (token, EXP) => {
		setToken((prevToken) => {
			return { token: token,expiration:EXP};
		});
		localStorage.setItem("token", token);
		localStorage.setItem("expirationDate", EXP);
	};
	const logout = () => {
		setToken((prevToken) => {
			return { token: null,expiration:null };
		});
		localStorage.removeItem("token");
		localStorage.removeItem("expirationDate");
	};
	const exists = localStorage.getItem("token") != null;

	useEffect(() => {
		if (localStorage.getItem("token")) {
			login(localStorage.getItem("token"), localStorage.getItem("expirationDate"));
			authContext.token = authToken.token;
			authContext.expiration = authToken.expiration;
		}else{
			logout();
		}
	},[exists]);

	return (
		<BrowserRouter>
			<Switch>
				<UserContext.Provider value={{ token: authToken.token ,expiration :authToken.expiration, login: login, logout: logout }}>
					{!authToken.token && (

							<Route component={Login} />
						
					)}

					{authToken.token && (
						
						<Switch>
							<Route path="/trips/add" exact component={AddTrip} />
							<Route path="/bookings/add" exact component={AddBooking} />
							<Route path="/coupons/add" exact component={AddCoupon} />
							<Route path="/trips" exact component={trips} />
							<Route path="/bookings" exact component={bookings} />
							<Route path="/coupons" exact component={Coupons} />
							<Route path="/down-payments" exact component={DownPayments}/>
							<Route path="/" exact component={Main} />
							<Route path="/login">
								<Redirect to="/" />
							</Route>
							<Route component={NotFoundPage}/>
						</Switch>
					)}

					{authToken.token && (
						<React.Fragment>
							<Footer />
							<ScrollToTop />
							<Logout />
						</React.Fragment>
					)}
				</UserContext.Provider>
			</Switch>
		</BrowserRouter>
	);
}

export default App;
