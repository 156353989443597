import PostRequest from "./PostRequest";
import SilentRefresh from "./silentRefresh";
async function FullRequest(authData,URL,Body){
    const request = await PostRequest(URL,Body,authData.token);
    if (request.status === 401){
        console.log("Silent Refresh");
        const success = await SilentRefresh(authData.login);
        if (success){
            return FullRequest(authData,URL,Body);
        }else{
            authData.logout();
        }
    }
    
    if (request.ok){
        console.log("request passed");
        return request;
    }
    const Data = await request.json();
    throw new Error(Data.ouput);

}

export default FullRequest;