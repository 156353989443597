import React, { createRef, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import "../addBooking/addBooking.css";
import UserContext from "../../AuthContext/auth";
import PostRequest from "../../functions/PostRequest";
const CouponForm = () => {
	const authContext = useContext(UserContext);
	const [display, setDisplay] = useState("none");
	const ErrorMessage = createRef();
	const history = useHistory();

	const token = authContext.token;

	const CouponType = createRef();
	const CouponCode = createRef();
	const limit = createRef();
	const EndDate = createRef();
	const CouponValue = createRef();

	const name = createRef();

	const totalPrice = createRef();

	const handelOnSubmit = async (event) => {
		event.preventDefault();
		const Body = {
			name: name.current.value,
			info: "Senior Trip Coupon",
			code: CouponCode.current.value,
			type: CouponType.current.value,
			end: EndDate.current.value,
			value: CouponValue.current.value,
			limit: limit.current.value,
			limit_per_user: true,
			active: true,
		};
		const createResponse = await PostRequest("/api/coupons/company/create", Body, token);

		if (createResponse.status === 200) {
			const Coupon = await createResponse.json();
			history.push(`/coupons/${Coupon.output}`);
		} else if (createResponse.status === 201) {
			const Coupon = await createResponse.json();
			ErrorMessage.current.innerText = "the coupon already Exists and you can re-activate it from this link";
			setDisplay("block");
			history.push(`/coupons/${Coupon.output}`);
		} else {
			ErrorMessage.current.innerText = "Error creating the coupon. that code already exists";
			setDisplay("block");
		}
	};
	return (
		<div className="container-fluid">
			<h1 className="h3 mb-2 text-gray-800">Add Coupon</h1>

			<div className="card shadow mb-4">
				<div className="card-header py-3 ">
					<div className="row row-table-with-btn">
						<div className="col-xl-6 col-md-6 mb-6 sub-head-with-btn">
							<h6 className="m-0 font-weight-bold text-primary">Coupon Data</h6>
						</div>
						<div className="col-xl-6 col-md-6 mb-6 sub-head-with-btn"></div>
					</div>
				</div>
				<div className="card-body">
					<form onSubmit={handelOnSubmit}>
						<div className="table-responsive">
							<table className="table table-bordered" width="100%" cellSpacing="0">
								<thead>
									<tr>
										<th>Data</th>
										<th>Input</th>
									</tr>
								</thead>

								<tbody>
									<tr>
										<td>Type</td>
										<td>
											<select required className="form-control" id="sel1" ref={CouponType}>
												<option value="0">Fixed</option>
												<option value="1">Percentage</option>
											</select>
										</td>
									</tr>
									<tr>
										<td>Coupon Name</td>
										<td>
											<input required type="text" className="form-control" aria-label="" ref={name} />
										</td>
									</tr>
									<tr>
										<td>Coupon Code</td>
										<td>
											<input
												required
												type="text"
												className="form-control"
												aria-label=""
												placeholder="Must be unique and readable value"
												ref={CouponCode}
												onInput={() => {
													CouponCode.current.value = CouponCode.current.value.toUpperCase();
												}}
											/>
										</td>
									</tr>
									<tr>
										<td>Coupon Value </td>
										<td>
											<input required type="number" className="form-control" aria-label="" placeholder="Fixed value 100 LE for example, percentage 10%" ref={CouponValue} />
										</td>
									</tr>
									<tr>
										<td>Coupon Limit </td>
										<td>
											<input required type="number" className="form-control" aria-label="" placeholder="150 LE" ref={limit} />
										</td>
									</tr>
									<tr>
										<td>End Date </td>
										<td>
											<input
												requiredtype="date"
												aria-label=""
												placeholder="150 LE"
												ref={EndDate}
												// className="form-control"
											/>
										</td>
									</tr>
								</tbody>
							</table>
							<div className="add-btn-div float-xl-right">
								<button type="submit" className="btn btn-primary add-btn">
									<i className="fas fa-paper-plane"></i> Create Coupon
								</button>
							</div>
							<div className="float-xl-right">
								total price: <p ref={totalPrice} style={{ color: "red" }}></p>
							</div>

							<div className="alert alert-danger alert-dismissible fade show" role="alert" style={{ display: display }}>
								<strong ref={ErrorMessage}></strong>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default CouponForm;
