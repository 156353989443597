
async function PostRequest(URL,Body,token){
    const request = await fetch(URL,{
        method:"POST", 
        body:JSON.stringify(Body),
        headers:{
            "content-type":"application/json",
            "Authorization":`Bearer ${token}`
        }
    });
    return request; 
}



export default PostRequest;